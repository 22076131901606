<template>
  <div class="container py-5">
    <p class="fw-bold">AMWC MONACO 2022</p>
    <p>時間：2022 03/31-04/02</p>
    <p>地點：MONACO</p>
    <p>
      Feeling thankful and excited to be amwcconference again this year. 
      <br>
      Last chance to visit us today at booth R22
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_04_1.jpg"
    />
    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_04.jpg"
    />
    
  </div>
</template>
<script>
export default {};
</script>
